
<template>
  <div class="container-fluid meet_detail">
    <div class="meet_detail_main">
      <ItemScroll ref="ItemScroll"
        ><div>
          <div class="meet_detail_body">
            <div class="media">
              <!-- <img
            class="media_avatar"
            alt=""
            :src="require('@/assets/images/home/default-avatar.png')"
          /> -->
              <div class="media_body">
                <div>
                  <span class="titleColor">{{ data.user_name }}</span>
                  <span class="media_role">楼主</span>
                </div>

                <div class="user_info titleColor">
                  <!-- {{ data.doctor_city }}  -->
                  {{ data.doctor_hospital }}
                </div>
                <div class="text-muted">发布于 {{ data.create_time }}</div>
              </div>
            </div>

            <h3 class="meet_title titleColor">{{ data.title }}</h3>
            <div class="meet_content titleColor">
              <p>{{ data.content }}</p>
            </div>
            <div class="article-footer">
              <!-- <div class="text-muted">
            <span>{{ data.read_count }} 浏览・</span>
            <span class="text-muted">{{ data.reply_num }} 讨论</span>
          </div> -->
              <div class="article_detail_label" v-if="data.has_diseases">
                <div>疾病分类: {{ data.diseases }}</div>
                <div>年龄: {{ data.age }}岁</div>
                <div>性别: {{ $config.sexs[data.sex] }}</div>
                <div>PS评分: {{ data.ps_score }}</div>
                <div>治疗线数: {{ data.treatment_lines }}</div>
                <div>当前PD-1用药周期: {{ data.current_pd1_cycle }}</div>
                <div
                  v-for="item in data.history_therapy.split(',')"
                  :key="item"
                >
                  历史联合, 治疗方案: {{ item }}
                </div>
                <div>疗效评估:{{ data.efficacy_evaluation }}</div>
                <div>irAE类型: {{ data.irae_type }}</div>
              </div>

              <div
                class="meet_content titleColor"
                style="display: flex; justify-content: end"
                v-if="data.show_attachments && false"
              >
                <CustomButton
                  class="reply_btn"
                  size="small"
                  type="primary"
                  @click="downLoad(data.file_attachments_url)"
                >
                  <span>下载并查看附件</span>
                </CustomButton>
              </div>
            </div>
          </div>

          <div class="comments_box">
            <div class="comments_box_title titleColor">
              全部讨论 ({{ data.replys?.length || 0 }}条)
            </div>
            <div class="comments-list">
              <div
                class="comments_list_item"
                v-for="item in data.replys"
                :key="item.relay_content"
              >
                <!-- <img
              :src="require('@/assets/images/home/default-avatar.png')"
              alt=""
              class="comments_avatar"
            /> -->
                <div class="comment_content">
                  <div class="comment_content_info">
                    <div class="comment_content_info_item">
                      <div class="user-nickname">
                        <span class="titleColor">{{ item.name }}</span>
                        <div
                          class="role_tips"
                          v-if="config.roleMap[item.role_code]"
                        >
                          {{ config.roleMap[item.role_code] }}
                        </div>
                        <div>
                          <!-- <span class="comment_time">时间</span> -->
                          <span class="text-muted">
                            {{ item.create_time }}</span
                          >
                        </div>
                        <!-- <div class="user-address">
                      <el-icon><LocationFilled /></el-icon>{{ item.city }}
                    </div> -->
                      </div>
                      <div class="text-muted">{{ item.hospital }}</div>
                    </div>
                    <!-- <div class="comment_like">
                  <span>赞</span>
                  <img
                    :src="require('@/assets/images/home/parse.png')"
                    alt=""
                  />
                </div> -->
                  </div>

                  <div class="comment_content_text">
                    <div>
                      {{ item.reply_content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ItemScroll>
    </div>
    <div class="reply_block" v-if="$tools.isLogin()">
      <div class="replay-item">
        <div class="global_text_input">
          <el-input
            placeholder="请输入"
            v-model="form.reply_content"
            @input="
              () => {
                initScreen('reply_content');
              }
            "
          ></el-input>
        </div>
        <CustomButton
          class="reply_btn"
          size="small"
          type="primary"
          @click="replay"
        >
          <span>提交</span>
        </CustomButton>
      </div>

      <div class="replay-item">
        <el-checkbox
          :disabled="!isHavePermisson()"
          v-model="form.join_meet"
          :label="1"
          size="small"
          >讨论并自动加入会议</el-checkbox
        >
      </div>
    </div>
    <Popup
      v-if="showPopup"
      :msg="config.msg"
      @doClose="doClose"
      @doSure="doSure"
    >
      <div class="live-detail-popup-main">
        <div class="live-detail-popup-content">
          <span class="live-detail-popup-tag">报名失败</span
          >,当前会议的讨论嘉宾已有专家报名成功,期待您的下次参与
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import CustomButton from "@/components/unit/CustomButton";
import Popup from "@/components/unit/Popup";
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "index",
  components: {
    CustomButton: CustomButton,
    Popup: Popup,
    ItemScroll: ItemScroll,
  },
  data() {
    return {
      config: {
        msg: {
          title: "温馨提示",
          align: "center",
          btngroup: {
            sure: true,
          },
        },
        roleMap: {
          ROLE_ZHUXI: "主席",
          ROLE_TAOLUN: "讨论",
          ROLE_JIANGZHE: "讲者",
        },
      },
      showPopup: false,
      data: {},
      form: {
        id: 0,
        reply_content: "",
        join_meet: false,
      },
      isComplete: false,
    };
  },
  created() {
    this.form.id = this.$route.query.id;
    this.loadData();
  },
  methods: {
    loadData() {
      let url = this.$tools.getURL(this.$urls.live.detail, this.$route.query);
      this.$axios.get(url).then((res) => {
        this.data = res.data;

        console.log(this.data);
        this.verifyPerson();
        this.getParticipants(this.data);

        setTimeout(() => {
          this.initScroll();
        }, 500);
      });
    },
    //参会人员
    getParticipants(data) {
      let participantsList = [
        ...data.ROLE_JIANGZHE,
        ...data.ROLE_TAOLUN,
        ...data.ROLE_ZHUXI,
      ];
      participantsList.forEach((v) => {
        this.data.replys.map((item) => {
          if (v.id == item.user_id) {
            item.role_code = v.role_code;
          }
        });
      });
    },
    verifyPerson() {
      if (this.isHavePermisson()) {
        this.form.join_meet = true;
      }
      let persons = [];
      for (let key in this.$config.types.roles) {
        persons = persons.concat(this.data[this.$config.types.roles[key]]);
      }
      let personCount =
        this.data.can_role_jiangzhe +
        this.data.can_role_taolun +
        this.data.can_role_zhuxi;
      this.isComplete = persons.length >= personCount;
      if (this.isComplete) this.form.join_meet = false;
    },
    //是嘉宾且有次数
    isHavePermisson() {
      let havePermission = (this.$store.state.common.userInfo.roles || []).find(
        (role) => role.role_code == this.$config.types.roles.tl
      );
      this.$store.state.common.userInfo.join_guest_count;
      console.log(this.data.ROLE_TAOLUN_INVITE, 222);
      let isInvate = (this.data?.no_join?.ROLE_TAOLUN || []).find(
        (x) => x.id == this.$store.state.common.userInfo.id
      );
      // 如果没有权益，不允许勾选
      if (!havePermission) return false;
      // 如果权益不够了，不允许勾选
      if (
        this.$store.state.common.userInfo.guest_count -
          this.$store.state.common.userInfo.join_guest_count <=
        0
      )
        return false;
      // 如果是讲者，不允许勾选
      if (this.$store.state.common.userInfo.id == this.data.user_id)
        return false;
      // 如果职称审核没过，不允许勾选
      if (this.$store.state.common.userInfo.work_card_ocr_status != 1)
        return false;
      // 如果在邀请中，可以勾选
      if (isInvate) return true;
      // 如果没位置了，不允许勾选
      if (this.isComplete) return false;
      return true;
    },
    initScreen(type) {
      if (this.form[type].charAt(0) === " ") {
        this.form[type] = this.form[type].trimStart();
      }
    },
    doClose() {
      this.showPopup = false;
    },
    doSure() {
      this.showPopup = false;
    },

    replay() {
      if (!this.$tools.isLogin()) {
        this.$router.push({
          path: "/login",
        });
        return;
      }
      if (this.loading || !this.form.reply_content)
        return this.$tips.error({ text: "请输入5个汉字及以上评论再提交" });
      if (this.form.reply_content.replace(/[^\u4e00-\u9fa5]/g, "").length < 5) {
        this.$tips.error({ text: "请输入5个汉字及以上评论再提交" });
        return;
      }
      this.loading = true;
      let currLoading = this.$tips.loading();
      this.$axios
        .post(this.$urls.live.replay, {
          ...this.form,
          join_meet: Number(this.form.join_meet),
        })
        .then((res) => {
          if (res.data.code == 2101) {
            this.showPopup = true;
          }
          this.data.replys.unshift({
            ...this.form,
            name: this.$store.state.common.userInfo.name,
            city: this.$store.state.common.userInfo.city,
            hospital: this.$store.state.common.userInfo.hospital,
            create_time: this.$tools.getDate("", "yyyy-MM-dd hh:mm:ss"),
          });
          setTimeout(() => {
            this.loading = false;
            currLoading.close();
          }, 1000);
          this.initScroll();
          this.form.reply_content = "";
          this.loadData();
          // this.$tips.success({text:"回复成功"})
        })
        .catch((err) => {
          console.log(err);
          setTimeout(() => {
            this.loading = false;
            currLoading.close();
          }, 1000);
        });
    },
    initScroll() {
      this.$nextTick(() => {
        this.$refs.ItemScroll.initScroll();
      });
    },
    downLoad(file) {
      window.open(file);
    },
  },
};
</script>
<style>
.meet_detail {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.meet_detail_main {
  flex: 1 0 0;
  overflow: hidden;
}
.meet_detail_body {
  padding: 1rem;
  margin-bottom: 2rem;
}
.meet_detail .media {
  display: flex;
  align-items: center;
  height: 4.5rem;
}
.meet_detail .media_avatar {
  width: 4.5rem;
  object-fit: contain;
  margin-right: 1rem;
}
.media_body {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.media_body .media_role {
  display: inline-block;
  padding: 1px 7px;
  color: white;
  background: rgb(184, 214, 55);
  border-radius: 4px;
  margin-left: 0.5rem;
}
.media_body .user_info {
  font-size: 0.75rem;
}
.meet_detail .text-muted {
  font-size: 0.75rem;
  color: #aaa;
}
.meet_title {
  margin: 1rem 0;
}
.meet_content {
  margin: 1rem 0;
  font-size: 0.8rem;
}
.article_detail_label {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.article_detail_label div {
  font-size: 0.8rem;
  color: #a6547c;
  margin-right: 0.5rem;
  padding: 7px 15px;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background: #fff6fe !important;
  box-sizing: border-box;
}
.comments-list {
}
.comments_box {
  padding: 0 1rem;
}
.comments_box_title {
  font-size: 0.8rem;
}
.comments_list_item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
}
.comments_avatar {
  width: 2.5rem;
  object-fit: contain;
  margin-right: 0.5rem;
}
.comment_content {
  flex: 1 0 0;
}
.comment_content_info {
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment_content_info_item {
  flex: 1 0 0;
}
.user-nickname {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}
.user-nickname span {
  margin-right: 0.7rem;
  font-size: 0.8rem;
}
.user-address {
  font-size: 0.8rem;
  display: flex;
  align-items: flex-end;
}
.comment_like {
  width: 4rem;
  text-align: center;
  color: #000;
  background: #f9fafb;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comment_like img {
  width: 1rem;
}
.comment_content_text {
  padding: 1rem 0;
  width: 14rem;
  word-wrap: break-word;
  font-size: 0.8rem;
}
.comment_time {
  color: #4e4e4e;
  font-size: 0.75rem !important;
  padding: 0.25rem 0.5rem;
  background: #e7e7e7;
  border-radius: 0.5rem;
}
.reply_block {
  /* height: 4rem; */
  margin-top: 1rem;
  padding: 1rem;
}
.replay-item {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}
.reply_btn {
  padding: 0 !important;
  width: 6rem;
  height: 40px;
  background: #e64638 !important;
  border: none;
  margin-left: 0.5rem;
}
.live-detail-popup-main {
  padding: 1rem 0;
  font-size: 0.9rem;
  line-height: 1.2rem;
}
.live-detail-popup-tag {
  color: #e64638;
}
.live-detail-popup-content {
}
.titleColor {
  color: #00005e;
  font-size: 0.8rem;
}
.role_tips {
  color: #fff;
  background: #22b783;
  padding: 0.05rem;
  border-radius: 0.1rem;
  font-size: 0.6rem !important;
  margin-right: 0.7rem;
}
.meet_detail .el-input__wrapper,
.meet_detail .el-input__wrapper.is-focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.meet_detail .el-input__inner {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>